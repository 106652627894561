import './FormHeading.css';

const FormHeadingProps = {
  // given class name
  className: '',
  // heading title
  title: '',
  // popup icon (info | error | warning | sucess | locked | pending)
  icon: 'none',
  // heading description
  description: '',
  // heading alignment (left | center | right)
  align: 'left',
  // size of the heading (large | medium | small)
  size: 'medium',
  // success icon
  success: false,
};

const icons = {
  info: 'danger-circle',
  error: 'danger-circle',
  warning: 'shield-fail',
  sucess: 'correct-round',
  locked: 'lock',
  pending: 'pending',
};

const FormHeading = (props = FormHeadingProps) => {
  // get default props
  const name = props.className || FormHeadingProps.className;
  const align = props.align || FormHeadingProps.align;
  const size = props.size || FormHeadingProps.size;
  const icon = props.icon || null;
  const success = props.success || false;
  // get text size typo version
  const version = size === 'small' ? 'v3' : size === 'large' ? 'v1' : 'v2';
  // return heading dom
  return (
    <div className={`form-element-heading ${align} ${size} ${name}`}>
      <div className='form-element-heading-icon-container'>
        {icon && (
          <div className={`form-element-heading-icon icon-${icons[icon]}`} data-icon={icon} />
        )}
      </div>
      <div className={`form-element-heading-title typo-heading-${version}`}>
        {props.title}
        {success && <div className='form-element-heading-success icon-tick-square-filled' />}
      </div>
      <div className={`form-element-heading-description typo-body-${version}`}>
        {props.description}
      </div>
    </div>
  );
};

export default FormHeading;
