import './FormButton.css';

const FormButtonProps = {
  // given class name
  className: '',
  // width of the button
  width: 'full',
  // button color (blue | black | blue-secondary | black-secondary)
  color: 'blue',
  // button disabled state
  disabled: false,
  // button icon (icon name from icons)
  icon: 'none',
  // side of icons (left | right| both)
  side: 'both',
  // click event
  onClick: null,
  //button type
  btnStyle: 'filled',
};

const FormButton = (props = FormButtonProps) => {
  // get given or default props
  const name = props.className || FormButtonProps.className;
  const width = props.width || FormButtonProps.width;
  const color = props.color || FormButtonProps.color;
  const disabled = props.disabled || FormButtonProps.disabled;
  const icon = props.icon || FormButtonProps.icon;
  const side = props.side || FormButtonProps.side;
  const btnStyle = props.btnStyle || FormButtonProps.btnStyle;
  // get button type
  const type = color.includes('secondary') ? 'secondary' : 'primary';
  // create button class name
  let className = `form-element-button ${name} typo-body-v2 ${type}`;
  // include button width
  className += ` form-element-button-width-${width}`;
  // include button theme
  className += ` form-element-button-theme-${color}`;

  className += ` form-element-button-${btnStyle}`;
  // create style set
  const styleSet = {};
  // include button width
  if (width !== 'full') {
    styleSet['width'] = width;
  }
  // click method
  const onClick = (event) => {
    // prevent default
    event.preventDefault();
    // callback function
    props.onClick && props.onClick(event);
  };
  // return button dom
  return (
    <button className={className} style={styleSet} disabled={disabled} onClick={onClick}>
      {(side === 'both' || side === 'left') && (
        <div className={`form-element-button-icon left icon-${icon}`} />
      )}
      <div className='form-element-button-content'>{props.children}</div>
      {(side === 'both' || side === 'right') && (
        <div className={`form-element-button-icon right icon-${icon}`} />
      )}
    </button>
  );
};

export default FormButton;
