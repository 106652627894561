import { createAsyncThunk } from '@reduxjs/toolkit';
import { createWatchlist, getWatchlist } from '../../api/lib/watchlist.api';

// Fetch existing watchlist from API
export const getWatchlistAction = createAsyncThunk(
  'watchlist/getWatchlist',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getWatchlist();
      console.log('Watchlist response: ' + JSON.stringify(response));
      return response.data; // Assuming the data contains list of symbols
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);

// Create a new watchlist
export const createWatchlistAction = createAsyncThunk(
  'watchlist/createWatchlist',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await createWatchlist(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  },
);
