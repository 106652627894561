import { apiClient } from '../apiClient';

export const paymentInitAPI = async (payload) => {
  try {
    return await apiClient.post('api/v1/payments/initPayment', payload, {});
  } catch (error) {
    throw error;
  }
};

export const cancelSubscriptionAPI = async (payload) => {
  try {
    return await apiClient.post('api/v1/users/subscription/cancelUserSubscription', payload, {});
  } catch (error) {
    throw error;
  }
};

export const getPaymentStatusAPI = async (requestId) => {
  try {
    return await apiClient.get(`api/v1/payments/getStatus?requestId=${requestId}`);
  } catch (error) {
    throw error;
  }
};

export const retryPaymentAPI = async () => {
  try {
    return await apiClient.post('/api/v1/payments/retryPayment');
  } catch (error) {
    throw error;
  }
};

export const downgradePlan = async (planData) => {
  try {
    return await apiClient.post('/api/v1/users/subscription/downgradeUserSubscription', planData);
  } catch (error) {
    throw error;
  }
};
