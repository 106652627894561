import { createSlice } from '@reduxjs/toolkit';
import {
  getAllSubscriptionAction,
  subscriptionsComparisonAction,
  subscriptionChangeRequestAction,
  subscriptionUnsubscibeAction,
} from './subscriptionThunk';
import {
  subscriptionFeaturesMapper,
  subscriptionPlansDataMapper,
} from '../../helpers/APIMappers/susbscriptionMapper';

const initialState = {
  isOnContinueClicked: false,
  isPlanChangeConfirmed: false,
  isPlanChangeStatusPopupOpen: false,
  isPlanChangeErrorPopupOpen: false,
  changeSubscriptionSuccessPopupContent: {},
  selectedSubscriptionId: '',
  selectedSubscriptionData: {},
  selectedSubscriptionType: 'monthly',
  subscriptionPlansData: {
    data: null,
    loading: false,
    error: null,
  },
  subscriptionComparisonData: {
    data: null,
    loading: false,
    error: null,
  },
  subscriptionChange: {
    loading: false,
    data: {
      isFree: false,
      isPaymentNeeded: false,
      isTrailEnabled: false,
      isDowngradeRequested: false,
      isUpgradeRequested: false,
      message: '',
    },
    error: null,
  },
  subscriptionUnsubscibe: {
    loading: false,
    data: null,
    error: null,
  },
  popupContent: {
    downgradePopupContent: {},
  },
};

const subscriptionSlice = createSlice({
  name: 'subscriptionSlice',
  initialState,
  reducers: {
    setSelectedSubscriptionId: (state, action) => {
      state.selectedSubscriptionId = action.payload;
    },
    setSelectedSubscriptionData: (state, action) => {
      state.selectedSubscriptionData = action.payload;
    },
    setSelectedSubscriptionType: (state, action) => {
      state.selectedSubscriptionType = action.payload;
    },
    setIsOnContinueClicked: (state, action) => {
      state.isOnContinueClicked = action.payload;
    },
    setIsPlanChangeConfirmed: (state, action) => {
      state.isPlanChangeConfirmed = action.payload;
    },
    setIsPlanChangeStatusPopupOpen: (state, action) => {
      state.isPlanChangeStatusPopupOpen = action.payload;
    },
    setIsPlanChangeErrorPopupOpen: (state, action) => {
      state.isPlanChangeErrorPopupOpen = action.payload;
    },
    setChangeSubscriptionSuccessPopupContent: (state, action) => {
      state.changeSubscriptionSuccessPopupContent = action.payload;
    },
    resetSubscriptionChange: (state) => {
      state.selectedSubscriptionId = '';
      state.selectedSubscriptionType = 'monthly';
      state.subscriptionChange.data.isFree = false;
      state.subscriptionChange.data.isPaymentNeeded = false;
      state.subscriptionChange.data.isTrailEnabled = false;
    },
    setIsDowngradeRequested: (state, action) => {
      state.subscriptionChange.data.isDowngradeRequested = action.payload;
    },
    setIsUpgradeRequested: (state, action) => {
      state.subscriptionChange.data.isUpgradeRequested = action.payload;
    },
    setDowngradePopupContent: (state, action) => {
      state.popupContent.downgradePopupContent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //   GET subscriptionPlansData Action
      .addCase(getAllSubscriptionAction.pending, (state = initialState) => {
        state.subscriptionPlansData.loading = true;
        state.subscriptionPlansData.error = null;
      })

      .addCase(getAllSubscriptionAction.fulfilled, (state = initialState, action) => {
        state.subscriptionPlansData.loading = false;
        state.subscriptionPlansData.error = null;
        state.subscriptionPlansData.data = subscriptionPlansDataMapper(action.payload);
      })
      .addCase(getAllSubscriptionAction.rejected, (state = initialState, action) => {
        state.subscriptionPlansData.loading = false;
        state.subscriptionPlansData.error = action.payload;
      })

      // GET subscriptionsComparisonAction data
      .addCase(subscriptionsComparisonAction.pending, (state = initialState) => {
        state.subscriptionComparisonData.loading = true;
        state.subscriptionComparisonData.error = null;
      })
      .addCase(subscriptionsComparisonAction.fulfilled, (state = initialState, action) => {
        state.subscriptionComparisonData.loading = false;
        state.subscriptionComparisonData.error = null;
        state.subscriptionComparisonData.data = subscriptionFeaturesMapper(action.payload);
      })
      .addCase(subscriptionsComparisonAction.rejected, (state = initialState, action) => {
        state.subscriptionComparisonData.loading = false;
        state.subscriptionComparisonData.error = action.payload;
      })

      // subscriptionChangeRequestAction
      .addCase(subscriptionChangeRequestAction.pending, (state) => {
        state.subscriptionChange.loading = true;
        state.subscriptionChange.error = null;
      })
      .addCase(subscriptionChangeRequestAction.fulfilled, (state, action) => {
        state.subscriptionChange.loading = false;
        state.subscriptionChange.data.isFree = action.payload.data.isFree;
        state.subscriptionChange.data.isPaymentNeeded = action.payload.data.isPaymentNeeded;
        state.subscriptionChange.data.isTrailEnabled = action.payload.data.isTrial;
        state.subscriptionChange.data.message = action.payload.data.message;
        if (action.payload.data.downgradeRequested) {
          state.subscriptionChange.data.isDowngradeRequested = true;
        }
        if (action.payload.data.upgradeRequested) {
          state.subscriptionChange.data.isUpgradeRequested = true;
        }
      })
      .addCase(subscriptionChangeRequestAction.rejected, (state, action) => {
        state.subscriptionChange.loading = false;
        state.subscriptionChange.error = action.payload;
      })

      //subscriptionUnsubscibeAction
      .addCase(subscriptionUnsubscibeAction.pending, (state = initialState) => {
        state.subscriptionUnsubscibe.loading = true;
        state.subscriptionUnsubscibe.error = null;
      })
      .addCase(subscriptionUnsubscibeAction.fulfilled, (state = initialState, action) => {
        state.subscriptionUnsubscibe.loading = false;
        state.subscriptionUnsubscibe.error = null;
        state.subscriptionUnsubscibe.data = action.payload;
      })
      .addCase(subscriptionUnsubscibeAction.rejected, (state = initialState, action) => {
        state.subscriptionUnsubscibe.loading = false;
        state.subscriptionUnsubscibe.error = action.payload;
      });
  },
});

export const {
  setSelectedSubscriptionId,
  setSelectedSubscriptionData,
  setSelectedSubscriptionType,
  resetSubscriptionChange,
  setIsOnContinueClicked,
  setIsDowngradeRequested,
  setIsUpgradeRequested,
  setIsPlanChangeConfirmed,
  setIsPlanChangeStatusPopupOpen,
  setIsPlanChangeErrorPopupOpen,
  setChangeSubscriptionSuccessPopupContent,
  setDowngradePopupContent,
} = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
