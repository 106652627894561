import { createSlice } from '@reduxjs/toolkit';
import { getWatchlistAction, createWatchlistAction } from './watchlistThunk';

const initialState = {
  watchlist: {
    data: [],
    loading: false,
    error: null,
  },
  createStatus: {
    loading: false,
    success: false,
    error: null,
  },
};

const watchlistSlice = createSlice({
  name: 'watchlist',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Watchlist
    builder
      .addCase(getWatchlistAction.pending, (state) => {
        state.watchlist.loading = true;
        state.watchlist.error = null;
      })
      .addCase(getWatchlistAction.fulfilled, (state, action) => {
        state.watchlist.loading = false;
        state.watchlist.data = action.payload;
      })
      .addCase(getWatchlistAction.rejected, (state, action) => {
        state.watchlist.loading = false;
        state.watchlist.error = action.payload;
      });

    // Create Watchlist
    builder
      .addCase(createWatchlistAction.pending, (state) => {
        state.createStatus.loading = true;
        state.createStatus.success = false;
        state.createStatus.error = null;
      })
      .addCase(createWatchlistAction.fulfilled, (state) => {
        state.createStatus.loading = false;
        state.createStatus.success = true;
      })
      .addCase(createWatchlistAction.rejected, (state, action) => {
        state.createStatus.loading = false;
        state.createStatus.error = action.payload;
      });
  },
});

export default watchlistSlice.reducer;
