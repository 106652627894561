// src/api/lib/news.api.js
import axios from 'axios';
import { getSession } from '../../helpers/helpers';

export const getNews = async (symbol) => {
  console.log('symbol 111', symbol);
  const authToken = getSession('auth_token');
  try {
    const response = await axios.get('https://asec.stg.arimac.digital/atrad-svc/api/news', {
      params: { symbol },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
